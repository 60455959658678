import React, { useState,useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {FaArrowCircleUp} from "react-icons/fa"
import { Link } from 'react-router-dom';
import TextField from '../components/TextField';

const MainPage = () => {

  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [segmentation, setSegmentation] = useState('');
  const [hostname, setHostname] = useState('');

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showButton, setShowButton] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    fetchCategories();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
  };

  const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 350) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
  };

  const fetchCategories = async () => {
    fetch('https://apisegmentation.props.id/getSegmentationCategory')
      .then((response) => response.json())
      .then((data) => setCategories(data))
      .catch((error) => console.error(error));
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };
  
  const filteredData = data.filter((item) => {
    if (searchTerm === '') return true;
    return Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const fieldsToSend = {};
    if (title) fieldsToSend.title = title;
    if (category) fieldsToSend.category = category;
    if (url) fieldsToSend.url = url;
    if (segmentation) fieldsToSend.segmentation = segmentation;
    if (hostname) fieldsToSend.hostname = hostname;

    if (Object.values(fieldsToSend).every((field) => field === "")) {
      toast.warning("Please fill at least one option to filter");
    } else {
      setLoading(true);
      try {
        const response = await fetch('https://apisegmentation.props.id/getSegmentationData', {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(fieldsToSend),
        });
        const result = await response.json();
        setData(result);

        console.log(fieldsToSend);

        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
      <div>
      <div>
        <form onSubmit={handleSubmit}>
            <h2 className="mb-2">Select filter options </h2>
            <hr />
            <div style={{display: 'flex'}}>
              <div style={{flex: '0.65'}}>
                <TextField
                  label="Title"
                  placeholder="Enter Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div style={{marginLeft: "15px", flex: '0.35'}}>
                <label style={{marginBottom:"0px"}}>Category</label>
                <select value={category} onChange={(e) => setCategory(e.target.value)}>
                  <option value="">Select category</option>
                  {categories.map((category, index) => (
                    <option key={index} value={category.category}>
                      {category.category}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <TextField
              label="URL"
              placeholder="Enter Url Name"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
            <TextField
              label='Segmentation'
              value={segmentation}
              onChange={(e) => setSegmentation(e.target.value)}
              placeholder="Enter Segmentation"
            />
            <TextField
              label='Hostname'
              value={hostname}
              onChange={(e) => setHostname(e.target.value)}
              placeholder="Enter Hostname"
            />
            <div style={{ marginTop: '18px' }}>
                <button type="submit" className="btn btn-primary btn-md" disabled={loading}>
                {loading ? 'Loading...' : 'Search'}
                </button>
            </div>
            <ToastContainer />
        </form>
      </div>
      {data.length !== 0 ?
      <div className='result'>
        <h2>Result</h2>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ display: "inline-flex", alignItems: "center"}}>
            <label style={{ fontWeight: "bold", color:"#999", fontSize: "14px", marginTop:"9px" }}>Show entries: </label>
            <select value={rowsPerPage} onChange={(e) => setRowsPerPage(e.target.value)} style={{ width:"55px",padding:"7px", marginLeft:"8px"}}>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div>
          <div style={{ display: "inline-flex", alignItems: "center", width:"300px", marginLeft:"60px"}}>
            <label style={{ fontWeight: "bold", color:"#999", fontSize: "14px", marginTop:"9px" }}>Search: </label>
            <input type="text" value={searchTerm} onChange={handleChange} style={{marginLeft:"8px"}}/>
          </div>
        </div>
        {filteredData && (
          <div className='table-responsive'>
            <table className='table table-striped' style={{fontSize:"12px"}}>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Category</th>
                  <th>URL</th>
                  <th>Segmentation</th>
                  <th>Hostname</th>
                </tr>
              </thead>
              <tbody>
                {currentRows.map((field, index) => (
                  <tr key={index}>
                    <td>{field.title}</td>
                    <td>{field.category}</td>
                    <td><a href={`https://${field.url}`} target="_blank">{field.url}</a></td>
                    <td>{field.segmentation}</td>
                    <td>{field.hostname}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <hr/>

        <div className="button-container">
          <button className="btn btn-primary btn-sm" onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
          {currentPage > 1 && (
            <>
              <button className="btn btn-primary btn-sm" onClick={() => setCurrentPage(1)}>1</button>
              {currentPage > 3 && <span style={{ marginRight: "5px" }}>...</span>}
            </>
          )}
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => {
            if (
              (pageNumber === currentPage - 1 && currentPage > 2) ||
              (pageNumber === currentPage && currentPage > 1 && currentPage < totalPages) ||
              (pageNumber === currentPage + 1 && currentPage < totalPages - 1)
            ) {
              return (
                <button key={pageNumber} className={`btn btn-sm ${pageNumber === currentPage ? 'btn-primary-active' : 'btn-primary'}`} onClick={() => setCurrentPage(pageNumber)}>{pageNumber}</button>
              );
            }
            return null;
          })}
          {currentPage < totalPages && (
            <>
              {currentPage < totalPages - 2 && <span style={{ marginRight: "5px" }}>...</span>}
              <button className="btn btn-primary btn-sm" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
            </>
          )}
          <button className="btn btn-primary btn-sm" onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
        </div>
      </div>      
      : null}
      <Link
          className="scroll-to-top-button"
          style={{ display: showButton ? 'block' : 'none' }}
          onClick={scrollToTop}
      >
          <FaArrowCircleUp size="30px" />
      </Link>
  </div>
  );
};

export default MainPage;