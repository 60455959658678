import React from 'react';

const TextField = (props) => {
  const { label, placeholder, value, onChange } = props;

  return (
    <div className="text-field-container">
      <label style={{marginBottom:"0px"}}>{label}</label>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default TextField;